<template>
  <v-container>
    <!-- zoom options -->
    <v-btn-group color="background" id="view-toggle" rounded="xl" variant="elevated" density="compact">

      <v-btn v-if="showZoom" size="small" class="px-4" icon>
        <v-icon>{{ icons.zoomPercentage }}</v-icon>
        <v-tooltip activator="parent" location="top" :attach="isFullScreen ? '#view-toggle' : undefined">
          <span>Zoom Percentage</span>
        </v-tooltip>

        <v-menu 
          location="top"
          activator="parent"
          :attach="isFullScreen ? '#view-toggle' : undefined"
        >
          <v-list>
            <v-list-item v-for="n in 8" :key="n" @click="zoomByPercentage(n)">
              <v-list-item-title>{{ n * 25 }} %</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomSelected">
        <v-icon>{{ icons.zoomSelected }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>Zoom Selected</span>
        </v-tooltip>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomExtents">
        <v-icon>{{ icons.zoomExtents }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>Zoom Extents</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showFullScreen" vertical></v-divider>
      <v-btn v-if="showFullScreen" size="small" class="px-4" icon @click="fullscreen">
        <v-icon>{{ icons.fullScreen }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>FullScreen</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showMultiSelect" vertical></v-divider>
      <v-btn v-if="showMultiSelect" size="small" class="px-4" icon @click="multiSelect">
        <v-icon :icon="multiSelection ? 'mdi:mdi-checkbox-multiple-marked-circle-outline' : 'mdi:mdi-checkbox-marked-circle-outline'"></v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>Toggle Multi Select</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showDownloads" vertical></v-divider>
      <v-btn v-if="showDownloads" size="small" class="px-4" icon @click="download">
        <v-icon icon="mdi:mdi-download-circle-outline"></v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>{{ lexicon.download}}</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showCameras" vertical></v-divider>
      <v-btn v-if="showCameras" size="small" class="px-4" icon >
        <v-icon>{{ icons.setView}}</v-icon>
        <CameraMenuList
        :isPerspective="cameraProjection"
        :customCameras="customCameras"
          v-on:cameraUpdated="cameraUpdated($event)"
          v-on:camerasUpdated="camerasUpdated($event)"  
          v-on:cameraSaved="cameraSaved($event)"  
          v-on:projectionUpdated="cameraProjectionUpdated($event)"  
        :isFullScreen="isFullScreen"
          />
      </v-btn>

      <v-divider v-if="showCameras" vertical></v-divider>
      <v-btn v-if="showCameras" size="small" class="px-4" icon>
        <v-icon>{{ icons.clipping }}</v-icon>
        <v-menu activator="parent" :attach="isFullScreen ? '#view-toggle' : undefined" open-on-hover
          offset-y :close-on-content-click="false" location="top">
          <v-card title="Clipping">
            <div class="px-2">
              <div class="d-flex">
                <v-checkbox :disabled="!clippingData.clipActive" @change="toggleClipLocal(clippingData.clipLocal)" density="compact"
                  hide-details v-model="clippingData.clipLocal" label="Local Clipping"></v-checkbox>
                <v-checkbox @change="toggleClipActive(clippingData.clipActive)" density="compact" class="pl-10" hide-details
                  v-model="clippingData.clipActive" label="Active"></v-checkbox>
              </div>
              <div class="d-flex"> <v-checkbox :disabled="!clippingData.clipLocal || !clippingData.clipActive"
                  @change="toggleClipShadows(clippingData.clipShadows)" density="compact" hide-details v-model="clippingData.clipShadows"
                  label="Clip Shadows"></v-checkbox>
                <v-checkbox :disabled="!clippingData.clipLocal || !clippingData.clipActive" @change="toggleClipIntersection(clippingData.clipIntersection)"
                  density="comfortable" hide-details v-model="clippingData.clipIntersection" label="Clip Intersection"></v-checkbox>
              </div>
              <div class="pb-3 px-2" style="width:200px;">
                <v-select @update:modelValue="selectClippingType($event)" :disabled="!clippingData.clipActive" :items="clippingType"
                  v-model="clippingData.clippingType" hide-details density="compact"
                  label="Select clipping type"></v-select>
              </div>
            </div>
            <ClippingSliders :axis="xAxis" :sliderValues="clippingData.clipDirectionsAndValues.find(e => e.key === xAxis)" v-on:applyClipping="applyClipping($event)"
              v-on:toggleFlip="toggleFlip($event)" v-on:clickOnsliderActive="clickOnsliderActive($event)" />
            <ClippingSliders :axis="yAxis" :sliderValues="clippingData.clipDirectionsAndValues.find(e => e.key === yAxis)" v-on:applyClipping="applyClipping($event)"
              v-on:toggleFlip="toggleFlip($event)" v-on:clickOnsliderActive="clickOnsliderActive($event)" />
            <ClippingSliders :axis="zAxis" :sliderValues="clippingData.clipDirectionsAndValues.find(e => e.key === zAxis)" v-on:applyClipping="applyClipping($event)"
              v-on:toggleFlip="toggleFlip($event)" v-on:clickOnsliderActive="clickOnsliderActive($event)" />
          </v-card>
        </v-menu>
      </v-btn>

      <v-divider v-if="showLayers" vertical></v-divider>
      <v-btn v-if="showLayers" size="small" class="px-4" icon>
        <v-icon>{{ icons.layer }}</v-icon>
        <LayerMenuList
          :layers="layers" 
          v-on:layersUpdated="layersUpdated($event)"
            v-on:layersLocked="layersLocked($event)" 
          :isFullScreen="isFullScreen"
        />
      </v-btn>

      <v-divider v-if="showModels" vertical></v-divider>
      <v-btn v-if="showModels" size="small" class="pl-4 pr-5" icon>
        <v-icon>{{ lexicon.model.icon }}</v-icon>
        <AssetMenuList
          :models="models" 
          v-on:modelUpdated="modelUpdated($event)"
          :isFullScreen="isFullScreen"
        />
      </v-btn>

    </v-btn-group>
  </v-container>
</template>

<script>
import AssetMenuList from "@/components/viewerSettings/AssetMenuList.vue";
import LayerMenuList from "@/components/viewerSettings/LayerMenuList.vue";
import CameraMenuList from "@/components/viewerSettings/CameraMenuList.vue";
import ClippingSliders from '../ui/ClippingSliders.vue';
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  setup() {
    return {icons, lexicon}
  },
  props: ["isFullScreen", "showFullScreen", "showZoom", "showDownloads", "showMultiSelect", "showLayers", "showModels", "layers", "customCameras", "cameraProjection", "multiSelection", "models", "showCameras", "clippingData"],
  data() {
    return {
      // clipLocal:true,
      // clipActive:true,
      // clipShadows:false,
      // clipIntersection:false,
      // selectedClippingType:'plane',
      clippingType:['plane', 'box'],
      xAxis:'x',
      yAxis:'y',
      zAxis:'z'
    }
  },
  methods: {
    zoomByPercentage(n) {
      this.$emit("zoomByPercentage", n * 25);
    },
    zoomExtents() {
      this.$emit("zoomExtents", {});
    },
    zoomSelected() {
      this.$emit("zoomSelected", {});
    },
    download() {
      this.$emit("download", {});
    },
    fullscreen() {
      this.$emit("fullscreen", {});
    },
    multiSelect() {
      this.isMultiSelect = ! this.isMultiSelect;
      this.$emit("multiSelect", {});
    },
    layersUpdated(e) {
      this.$emit("layersUpdated", e);
    },
    layersLocked(e) {
      this.$emit("layersLocked", e);
    },
    modelUpdated(e) {
      this.$emit("modelUpdated", e);
    },
    cameraUpdated(e) {
      this.$emit("cameraUpdated", e);
    },
    cameraSaved(e){
      this.$emit("cameraSaved", e);
    },
    camerasUpdated(e){
      this.$emit("camerasUpdated", e);
    },
    cameraProjectionUpdated(e){
      this.$emit("cameraProjectionUpdated", e);
    },

    selectClippingType(value) {
      this.$emit('selectClippingType', value)
    },
    applyClipping(value) {
      this.$emit('applyClipping', value)
    },
    toggleClipLocal(value) {
      this.$emit('toggleClipLocal', value);
    },
    toggleClipActive(value) {
      this.$emit('toggleClipActive', value);
    },
    toggleClipShadows(value) {
      this.$emit('toggleClipShadows', value);
    },
    toggleClipIntersection(value) {
      this.$emit('toggleClipIntersection', value);
    },
    toggleFlip(value) {
      this.$emit('toggleFlip', value);
    },
    clickOnsliderActive(value) {
      this.$emit('clickOnsliderActive', value);
    }
  },
  components: {
    LayerMenuList, CameraMenuList,AssetMenuList,ClippingSliders,
  },
};
</script>

<style scoped>

</style>