<template>
  <div class="px-5 py-3" style="height: 100%">
    <v-col class="d-flex justify-space-between align-center">
      
      <span class="header"> {{ lexicon.notebook.plural }}
        <HelpButton :lexiconItem="lexicon.notebook"/>
      </span>

      <v-text-field
        v-model="search"
        label="Search"
        class="mx-8"
        clearable hide-details
        prepend-inner-icon="mdi:mdi-magnify"
        density="compact" variant="outlined"
        @update:modelValue="updateSearch"
        @click:clear="clearSearch"
      ></v-text-field>
      <v-sheet>
        <!-- Create New Notebook -->
        <div>
          <v-btn @click="createNewNotebookDialog = true" color="primary" class="text-white rounded mr-2">
            <v-icon class="mr-2">
              {{ lexicon.notebook.iconAdd }}
            </v-icon> 
            {{messages.notebookAdd}}
          </v-btn>
          <v-dialog v-model="createNewNotebookDialog" width="500">
            <v-card>
              <v-card-title class=""> {{messages.notebookAdd}} </v-card-title>
              <v-card-text>
              <v-text-field hide-details variant="outlined" class="popUpTextField" v-model="notebookName"
                :label="messages.notebookName"></v-text-field>
              <v-textarea hide-details variant="outlined" class="popUpTextField" v-model="notebookDescription" rows="2"
                :label="messages.notebookDescription"></v-textarea>
                <v-select 
                  class="popUpTextField"
                  v-model="selectedTemplate"
                  :items="getTemplates"
                  item-title="name"
                  hide-details
                  variant="outlined"
                  :label="messages.templateSelect"
                  return-object
                  ></v-select>
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darkGrey" class="text-white" @click="cancelCreateNotebook()"> {{ lexicon.cancel }}
                </v-btn>
                <v-btn :disabled="!notebookName" :loading="createNotebookLoading" color="primary"
                  @click="createNewNotebook()"> {{ lexicon.create }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>            
            <v-btn v-if="!viewList" variant="outlined" light @click="viewList = !viewList" style="width:150px;">
              <v-icon dark class="mr-2">
                {{icons.displayList}}
              </v-icon> Display List
            <v-tooltip location="bottom" activator="parent"><span>Show List View</span></v-tooltip>
            </v-btn>

            <v-btn v-if="viewList" variant="outlined" light @click="viewList = !viewList" style="width:150px;">
              <v-icon dark class="mr-2">
                {{icons.displayGrid}}
              </v-icon> Display Grid
            <v-tooltip location="bottom" activator="parent"><span>Show Grid View</span></v-tooltip>
            </v-btn>
        </div>
      </v-sheet>
    </v-col>
    
    <v-sheet>
     
    <v-container py-0 px-0 v-if="!viewList" fluid>
      <v-row class="d-flex flex-wrap" v-if="getNotebooks">
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" :style="`cursor: pointer;`" class="px-2 py-2"
          v-for="notebook in filteredNotebooks" :key="notebook._id">
          <NotebookCard :notebook="notebook" 
          @onDeleteNotebookClick="onDeleteNotebookClick(notebook)"
          @onCreateTemplateClick="onCreateTemplateClick(notebook)"
          @onNotebookClick="onNotebookClick(notebook)"
          @onEditNotebookClick="onEditNotebookClick(notebook)"
          @onCopyNotebookClick="onCopyNotebookClick(notebook)"
          @onMakePublic="onMakePublicClick(notebook)"
          @onSetImageClick="onSetImageClick(notebook)"/>
        </v-col>
      </v-row>
    </v-container>

    <v-container py-0 px-0 v-if="viewList">
      <v-data-table
        :headers="headers"
        :items="filteredNotebooks"
        disable-pagination
        dense
      >
        <template v-slot:item="{ item }">
          <NotebookItem :notebook="item"
          @onDeleteNotebookClick="onDeleteNotebookClick(item)"
          @onCreateTemplateClick="onCreateTemplateClick(item)"
          @onNotebookClick="onNotebookClick(item)"
          @onEditNotebookClick="onEditNotebookClick(item)"
          @onCopyNotebookClick="onCopyNotebookClick(item)"
          @onMakePublic="onMakePublicClick(item)"
          @onSetImageClick="onSetImageClick(item)"/>
        </template>
      </v-data-table>
    </v-container>
  </v-sheet>
  </div>

  <!-- Delete Notebook Dialog -->
  <AreYourSurePopUp 
    :dialog="deleteNotebookDialog"
    @toggle="deleteNotebookDialog=!deleteNotebookDialog"
    @actionClicked="deleteNotebook"
    :loading="deleteNotebookLoading"
    >{{messages.notebookDelete}}
  </AreYourSurePopUp>

  <!-- Create Notebook Copy Dialog -->
  <v-dialog centered variant="outlined" v-model="copyNotebookDialog" width="600">
      <v-card>
        <v-card-title> Duplicate Notebook </v-card-title>
        <v-text-field
          v-model="copyNotebookName"
          variant="outlined"
          hide-details
          label="Notebook Name"
          class="mx-3 pa-2"
        ></v-text-field>
        <div class="d-flex justify-end pb-2">
          <v-card-actions>
            <v-btn
              @click="copyNotebookDialog = false"
              class="mx-2 text-white"
              medium
              color="darkGrey"
            >
              {{ lexicon.cancel}}
            </v-btn>
            <v-btn
              @click="copyNotebook"
              :loading="copyNotebookLoading"
              :disabled="!this.copyNotebookName"
              color="primary"
            >
              {{ lexicon.create}}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- Create New Template Dialog -->
    <v-dialog centered variant="outlined" v-model="createTemplateDialog" width="600">
      <v-card>
        <v-card-title> {{messages.templateCreate}} </v-card-title>
        <v-text-field
          v-model="templateName"
          variant="outlined"
          label="Template Name"
          class="mx-3 pa-2"
        ></v-text-field>
        <div class="d-flex justify-end pb-2">
          <v-card-actions>
            <v-btn
              @click="createTemplateDialog = false"
              color="darkGrey"
            >
              {{ lexicon.cancel}}
            </v-btn>
            <v-btn
              @click="createTemplate"
              :loading="createTemplateLoading"
              :disabled="!this.templateName"
              color="primary"
            >
              {{ lexicon.create}}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- Edit Notebook Dialog -->
    <v-dialog v-model="editNotebookDialog" width="500">
      <v-card flat>
        <v-card-title> Rename Notebook </v-card-title>
          <v-text-field
            class="popUpTextField"
            variant="outlined"
            hide-details
            v-model="selectedNotebook.name"
            label="Name"
          ></v-text-field>
          <v-textarea
            class="popUpTextField"
            variant="outlined"
            hide-details
            v-model="selectedNotebook.description"
            rows="2"
            label="Description"
          ></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-white"
              medium
              color="darkGrey"
              @click="editNotebookDialog = false"
            >
              {{ lexicon.cancel}}
            </v-btn>
            <v-btn
              :disabled="!selectedNotebook.name"
              :loading="editNotebookLoading"
              color="primary"
              @click="editNotebook"
            >
              {{ lexicon.save}}
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <SetImage 
      :dialog="selectImageDialog"
      :notebook="selectedNotebook"
      @close="setImageDialogClosed"
      @imageChanged="updateSearch()">
    </SetImage>

    <SharePublicLink 
      :createPublicUrlDialog="createPublicUrlDialog"
      :notebook="selectedNotebook"
      @closed="SharePublicLinkDialogClosed"
    ></SharePublicLink>

</template>

<script>
import NotebookCard from "../components/NotebookCard.vue";
import NotebookItem from "../components/NotebookItem.vue";
import HelpButton from "../components/ui/HelpButton.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";


import { storeToRefs } from 'pinia';
import { useEllipseStore } from "@/store/EllipseStore.js";
import AreYourSurePopUp from "../components/popUps/AreYouSure.vue";
import SetImage from "../components/popUps/SetImage.vue";
import SharePublicLink from "../components/popUps/SharePublicLink.vue";

export default {
  components: { 
    NotebookCard, 
    NotebookItem, 
    AreYourSurePopUp, 
    HelpButton,
    SetImage,
    SharePublicLink 
  },
  setup(){
    const ellipseStore = useEllipseStore()
    const {
      getNotebooks,
    } = storeToRefs(ellipseStore)
    const {
      setNotebooks,
      setTemplates,
    } = ellipseStore
    return {
      ellipseStore,
      getNotebooks,
      setNotebooks, 
      setTemplates, 
      lexicon, 
      messages, 
      icons
    }
  },
  data() {
    return {
      createNewNotebookDialog: false,
      notebookName: "",
      notebookDescription: "",
      createNotebookLoading: false,
      viewList: false,
      selectedTemplate:{},
      createNewNotebookDialog: false,
      createPublicUrlDialog: false,
      selectedNotebook:null,
      copyNotebookDialog:false,
      copyNotebookName:"",
      createTemplateDialog:false,
      createTemplateLoading:false,
      templateName:"",
      copyNotebookLoading:false,
      deleteNotebookDialog:false,
      deleteNotebookLoading:false,
      editNotebookDialog:false,
      editNotebookLoading:false,
      selectImageDialog: false,
      search: '',
      headers: [
        { title: ' ', value: 'image', align: 'start', sortable: false, width: '80px' },
        { title: 'Name', value: 'name', align: 'start',  sortable: true  },
        { title: 'Status', key: '', align: 'start' ,  sortable: true, width: '80px' },
        { title: 'Author', key: 'createdBy', align: 'start', sortable: true },
        { title: 'Date', key: 'createdAt', align: 'start', sortable: true, width: '180px' },
        { title: ' ', key: 'actions', align: 'start', sortable: false, width: '80px' },
      ],
      filteredNotebooks: []
    };
  },
  computed: {
    getTemplates() {
      var templates = [{name:"Blank Notebook",id:null}]
      templates = templates.concat(this.ellipseStore.getTemplates);
      return templates;
    },
  },
  watch: {},
  async mounted() {
    await this.setNotebooks()
    await this.setTemplates()
    this.selectedTemplate = this.getTemplates[0]
    this.filteredNotebooks = this.getNotebooks
  },
  created() {

  },  
  methods: {
    async createNewNotebook() {
      this.createNotebookLoading = true;
      let postData = {
        name: this.notebookName,
        description: this.notebookDescription
      };
      if(this.selectedTemplate!=null)postData.template_id = this.selectedTemplate._id;
      // console.log("postData",postData);
      await this.$auth.$api.post("/api/notebooks/new", postData);
      await this.setNotebooks()
      this.updateSearch()
      this.createNotebookLoading = false;
      this.createNewNotebookDialog = false;
    },
    async cancelCreateNotebook() {
      this.createNewNotebookDialog = false;
      this.notebookName = "";
      this.notebookDescription = "";
    },
    onNotebookClick(notebook) {
      this.$router.push(`/notebook/${notebook._id}`);
    },
    onDeleteNotebookClick(notebook) {
      this.selectedNotebook = notebook;
      this.deleteNotebookDialog = true
    },
    async deleteNotebook() {
      this.deleteNotebookLoading = true;
      let route = `/api/notebook/${this.selectedNotebook._id}/delete`;
      await this.$auth.$api.delete(route)
        .then(response => {
          console.log("response", response);
        })
        .catch((e) => {
          console.log("Error", e);
        });
      let deletedNotebookIndex = this.getNotebooks.indexOf(this.selectedNotebook);
      this.getNotebooks.splice(deletedNotebookIndex, 1);
      this.deleteNotebookLoading = false;
      this.deleteNotebookDialog = false;
      this.selectedNotebook = null;
    },
    onEditNotebookClick(notebook) {
      this.selectedNotebook = notebook;
      this.editNotebookDialog = true;
    },
    async editNotebook() {
      this.editNotebookLoading = true;
      let postData = {
        name: this.selectedNotebook.name,
        description: this.selectedNotebook.description
      };
      let route = `/api/notebook/${this.selectedNotebook._id}/edit-info`;
      await this.$auth.$api.post(route, postData)
        .then(response => {
          //console.log("response", response);
        })
        .catch((e) => {
          console.log("Error", e);
        });
      await this.setNotebooks()
      this.editNotebookLoading = false;
      this.editNotebookDialog = false;
    },
    onCreateTemplateClick(notebook) {
      this.selectedNotebook = notebook;
      this.createTemplateDialog = true;
    },
    async createTemplate(){
      this.createTemplateLoading = true;
      let postBody = {
        notebook_id:this.selectedNotebook._id,
        template_name:this.templateName
      };
      let createTemplate = await this.$auth.$api.post(
        `/api/templates/create_template`,
        postBody
      );
      await this.setTemplates()
      this.templateName = "";
      this.createTemplateLoading = false;
      this.createTemplateDialog = false;
      this.selectedNotebook = null;
    },
    onCopyNotebookClick(notebook) {
      this.selectedNotebook = notebook
      this.copyNotebookDialog = true;
    },
    onSetImageClick(notebook){
      this.selectedNotebook = notebook
      this.selectImageDialog = true
    },
    onMakePublicClick(notebook){
      this.createPublicUrlDialog = true
      this.selectedNotebook = notebook
    },  
    async copyNotebook(){
      this.copyNotebookLoading = true;
      let postData = {
        name: this.copyNotebookName,
        description: this.selectedNotebook.description,
        copiedNotebookId:this.selectedNotebook._id
      };
      // console.log("postData",postData);
      await this.$auth.$api.post("/api/notebooks/new", postData);
      await this.setNotebooks()
      this.copyNotebookLoading = false;
      this.copyNotebookDialog = false;
    },
    setImageDialogClosed(){
      this.selectImageDialog = false
      this.selectedNotebook = null
    },
    updateSearch(){
      let searchText = this.search.toLowerCase();
      let filtered = this.getNotebooks.filter(notebook => {
        const matchesUserEmail = notebook.users.some(user => {
          if (user.user) {
            if (user.user.email) return user.user.email.toLowerCase().includes(searchText)
            if (user.user.name) return user.user.name.toLowerCase().includes(searchText)
          }
          else {
            if (user.email) return user.email.toLowerCase().includes(searchText)
            if (user.name) return user.name.toLowerCase().includes(searchText)
          }
        });
        return notebook.name.toLowerCase().includes(searchText) ||
          matchesUserEmail ||
          notebook.createdBy.user.email.toLowerCase().includes(searchText)
      });
      this.filteredNotebooks = filtered
    },
    clearSearch(){
      this.search = ''
      this.filteredNotebooks = this.getNotebooks
    },
    SharePublicLinkDialogClosed(){
      this.createPublicUrlDialog = false
      this.selectedNotebook = null

    }
  },
};
</script>

<style scoped>
.v-col-md-4 {
  min-width: 275px !important
}

#info-btn {
  position: absolute; 
  bottom: 1px; 
  left: 5px; 
  outline: none;
}

</style>
