<template>
    <div class="widgetPadding pa-0" style="height:100%">
        <!-- Corner Icons for View and Edit Mode -->
        <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
        <!-- Loading Spinner -->
        <Spinner :show="(getAttrData.length === 0 && getSelectedPageDatasets.length != 0) || datasetSwitchingLoading">
        </Spinner>
        <!-- No Dataset Warning -->
        <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
        <!-- Widget Title Header -->
        <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

        <!-- Header Description -->
        <v-container class="px-2 pt-2 pb-0 d-flex justify-end">

            <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader">
                <WidgetDropdown 
                    :prefix="'Sort By '" 
                    :dropdownItems="getAttrHeadersNumerical"
                    :staticName="(this.grid_item.instance_setting.data.sortingAttr.replace('None', 'Count'))"
                    :dropDownName="(this.grid_item.instance_setting.data.sortingAttr.replace('None', 'Count'))"
                    :hasDropdown="false" 
                    :onClick="onSortingSourceChanged">
                </WidgetDropdown>
            </h5>

        </v-container>

        <!-- Chart -->
        <div ref="chartCanvas" :id="canvasId()" style="height: 100%; width:100%"></div>

    </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../ui/WidgetTitle.vue";
import chartMgrInstance from "@ttcorestudio/viewer_data/library_files/PlotMgr.js";
import WidgetDropdown from "../ui/WidgetDropdown.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

//globals
//these variables are shared accross all component instances
let chartManager = {};
let chartInstances = {};
let chartCount = 0;

export default {
    components: { Spinner, NoDataWarning, WidgetIcon,WidgetTitle,WidgetDropdown },
    props: ["grid_item"],
    setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getFilterByData,
      getAttrData,
      getAttrHeadersNumerical,
      getAttrHeaderNames,
      getUniqueAttrTotalByNumericalHeader
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets,
      getSelectedPageDataset
    } = storeToRefs(assetsStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {notebookPropsStore, dataGraphicsStore, assetsStore,
    getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset,
    getSelectedPageDatasets, getEditMode, getColorByData, getFilterByData, getAttrData, getAttrHeadersNumerical, getAttrHeaderNames,
    setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements, getUniqueAttrTotalByNumericalHeader,
    }
  },
    data() {
        return {
            updatingWidgets: false,
            datasetSwitchingLoading: false,
            selectedAttrs: [],
            sortingAttr: "None",
            sortingAttrType: "string",
            isFlipped:true,
            gridX:true,
            gridY:false,
            axisX:true,
            axisY:false,
      filter:{
        filterType: "Range",
        field: "None",
        range: this.range,
      },
        };
    },
    computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    },
    created() {
        this.verifyInstanceSettings();
    },
    async mounted() {
        this.verifyInstanceSettings();
        await this.creatingInstanceAndCanvas();

    setTimeout(async () => {
           await this.updateChartValues();
    }, 500);
    
    //Listen for changes in the notebookPropsStore
    this.notebookPropsStore.$onAction(({name, args}) => {
      if (name === 'setHighlightedElements') {
        this.onNewElementsHighlighted()
      }
      if (name === 'setGlobalHighlightColor'){
        this.globalHighlightColorEvent(args[0])
      }
      if (name === 'setGlobalSelectionColor'){
        this.globalSelectionColorEvent(args[0])
      }
      if (name === 'setGlobalSignificantDigits'){
        this.globalSignificantDigitsEvent(args[0])
      }
    })
    //Listen for changes in the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByProperties') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.newDatasetLoadStartedEvent()
        }
      })
    })


    },
    beforeUnmount() {
        if (chartInstances[this.grid_item.i]) {
            chartMgrInstance.clearPlotByName(this.grid_item.i);
            chartInstances[this.grid_item.i].destroy();
            delete chartInstances[this.grid_item.i];
        }
    },
    watch: {
        getSelectedElements: {
            deep: true,
            handler() {
                if (chartInstances[this.grid_item.i]) {
                    chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
                }
            }
        },
        "grid_item.instance_setting.data.selectedAttrs": function () {
            this.selectedAttrChanged();
        },
        "grid_item.instance_setting.data.sortingAttr": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.gridX": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.gridY": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.axisX": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.axisY": function () {
            this.updateChartValues();
        },
    },
    methods: {
        canvasId() {
            return "chart" + this.grid_item.i;
        },
        verifyInstanceSettings() {

            if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

            if(this.getUniqueAttrTotalByNumericalHeader.length>1){
                if(this.getUniqueAttrTotalByNumericalHeader[1].total < 1000 && !this.grid_item.instance_setting.data.selectedAttrs) {
                    this.selectedAttrs.push(this.getUniqueAttrTotalByNumericalHeader[1].name);
                }
            }
            if (!this.grid_item.instance_setting.data.selectedAttrs) this.grid_item.instance_setting.data.selectedAttrs = this.selectedAttrs;
            if (!this.grid_item.instance_setting.data.sortingAttr) this.grid_item.instance_setting.data.sortingAttr = this.sortingAttr;
            if (!this.grid_item.instance_setting.data.sortingAttrType) this.grid_item.instance_setting.data.sortingAttrType = this.sortingAttrType;

            if (!this.grid_item.instance_setting.data.displaySettings) this.grid_item.instance_setting.data.displaySettings = {};
            if (!('gridX' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.gridX = this.gridX;
            if (!('gridY' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.gridY = this.gridY;
            if (!('axisX' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisX = this.axisX;
            if (!('axisY' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisY = this.axisY;
            if (!('isFlipped' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.isFlipped = this.isFlipped;

            if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};

        },
        newDatasetLoadStartedEvent() {
            this.datasetSwitchingLoading = true;
        },
        selectedAttrChanged() {
            if (this.grid_item.instance_setting.data.sortingAttr) {
                let result = this.getAttrHeaderNames.filter(a => a === this.grid_item.instance_setting.data.sortingAttr);
                if (result.length === 0) {
                    this.grid_item.instance_setting.data.sortingAttr = this.getAttrHeadersNumerical[0];
                }
            }

            this.updateChartValues();
        },
        async updateChartValues(){
            if (!chartInstances[this.grid_item.i]) return;
                chartInstances[this.grid_item.i].addLinePlot(
                    this.getAttrData,
                    "ellipseId",
                    this.grid_item.instance_setting.data.selectedAttrs,
                    this.grid_item.instance_setting.data.sortingAttr,
                    this.grid_item.instance_setting.data.displaySettings.isFlipped,
                    { 
                        xGrid: this.grid_item.instance_setting.data.displaySettings.gridX, 
                        yGrid: this.grid_item.instance_setting.data.displaySettings.gridY,
                        xAxis: this.grid_item.instance_setting.data.displaySettings.axisX, 
                        yAxis: this.grid_item.instance_setting.data.displaySettings.axisY
                    }
                );
                await this.onNewColorByUpdated();
                await this.onNewElementsSelected();
                await this.onNewElementsHighlighted();
        },
        datasetUpdateEvent() {
            //NOT IMPLEMENTED
        },
        globalHighlightColorEvent(highlight_color) {
            if (!chartInstances[this.grid_item.i]) return;
                chartInstances[this.grid_item.i].highlightColor = highlight_color;
        },
        globalSelectionColorEvent(selection_color) {
            if (!chartInstances[this.grid_item.i]) return;
                chartInstances[this.grid_item.i].selectionColor = selection_color;
        },
        globalSignificantDigitsEvent(significant_digits) {
            if (!chartInstances[this.grid_item.i])  return;
                chartInstances[this.grid_item.i].digits = significant_digits;
        },
        async creatingInstanceAndCanvas() {
            //Get Div for new Viewer
            chartCount += 1;
            let canvasId = this.grid_item.i;
            let canvas = this.$refs.chartCanvas;

            let selectionColor = this.getGlobalProperty.global_setting.selection_color;

            let highlightColor = this.getGlobalProperty.global_setting.highlight_color;
            let backgroundColor = this.grid_item.local_setting.foreground_color;
 
            let defaultColor = this.$vuetify.theme.current.colors.darkSlate;

            let customChartSettings = {
                defaultColor: defaultColor,
                backgroundColor: backgroundColor,
                selectionColor: selectionColor,
                highlightColor: highlightColor,
            };

            let response = chartMgrInstance.addPlotInstance("line", canvas, canvasId, customChartSettings);

            if (response["status"] == "success") {
                chartInstances[this.grid_item.i] = chartMgrInstance.getPlotByName("line", canvasId);
                this.updateViewerEvents(this.grid_item.i);
            }
        },
        async onNewElementsSelected() {
            if (!chartInstances[this.grid_item.i]) return;
            chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
        },
        async onNewElementsHighlighted() {
            if (!chartInstances[this.grid_item.i]) return;
            chartMgrInstance.highlightElementsInAllPlots(this.getHighlightedElements);
        },
        async onNewColorByUpdated() {
            if (!chartInstances[this.grid_item.i]) return;
            if(!this.getColorByData.graphicData) {
                chartInstances[this.grid_item.i].clearAllColorBy();
                return;
            } 
            chartInstances[this.grid_item.i].colorElements(this.getColorByData.graphicData.ids,this.getColorByData.graphicData.colors);
        },
        async onNewFilterByUpdated() {
            //NOT IMPLEMENTED
        },
        async updateWidget() {
            let postData = {
                widgets: [this.grid_item],
            };

            let update_widgets = await this.$auth.$api.post(
                `/api/widget/update_widgets/?urlType=${this.$route.name}`,
                postData
            );
        },
        updateDisplaySettings() {
            //NOT IMPLEMENTED
        },
        onSortingSourceChanged(value){
        
      },
        updateViewerEvents(viewerId) {
            //Multi Select Refactor Here....
            chartInstances[this.grid_item.i].highlightCheck(true);
            chartInstances[viewerId].registerEvent("element-select", this.elementSelectEvent);
            chartInstances[viewerId].registerEvent("element-highlight", (e) => {this.setHighlightedElements(e);});
        },
        elementHighlightEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
        },
        elementSelectEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
        },
    },
};
</script>
  
<style scoped>
.v-input:deep(.v-select__selection) {
    display: flex;
    align-items: center;
}

.v-input:deep(.v-select__selection-text) {
    font-size: 14px !important;
    width: 170px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px
}
</style>
  