/*
 * List of all possible Icons organized as
 * 'Icon details' objects. This array is used
 * to instantiate the Icon standardization across
 * the Ellipse App to main consistency.
 */

/* Side Panel Tab Icons */
export const setting = "fa-solid fa-gears fa-fw";
export const properties = "mdi:mdi-view-list";
export const attachements = "fa-solid fa-fw fa-folder-tree";

/* Widget Control Options */
export const cursorButton = "mdi:mdi-button-cursor";
export const lineWeight = "mdi:mdi-format-line-weight";
export const labelSize = "mdi:mdi-format-size";

export const azimuth = "mdi:mdi-axis-z-rotate-clockwise";
export const altitude = "mdi:mdi-horizontal-rotate-clockwise";
export const ground = "mdi:mdi-arrow-collapse-vertical";
export const latitude = "mdi:mdi-latitude";
export const longitude = "mdi:mdi-longitude";

/* Site Actions */
export const about = "fa-solid fa-circle-question";
export const userGuide = "fa-solid fa-book";
export const information = "fa-solid fa-circle-info";
export const help = "mdi:mdi-help";
export const price = "fa-solid fa-credit-card";

/* Contact Actions */
export const contact = "fa-solid fa-paper-plane";
export const twitter = "fa-brands fa-twitter fa-fw";
export const instagram = "fa-brands fa-instagram fa-fw";
export const linkedIn = "fa-brands fa-linkedin-in fa-fw";

/* Attachment Actions */
export const attachment = "fa-solid fa-fw fa-paperclip";
export const upload = "fa-solid fa-fw fa-arrow-up-from-bracket";
export const cloudUpload = "fa-solid fa-fw fa-cloud-arrow-up";
export const download = "fa-solid fa-download";
export const zipFile = "fa-solid fa-fw fa-file-zipper";
export const sampleFile = "fa-solid fa-fw fa-box";
export const linkFile = "mdi:mdi-link";
export const image = "fa-solid fa-image"

/* Menu Options */
export const menu = "fa-solid fa-ellipsis-v";
export const rename = "fa-solid fa-fw fa-pen-to-square";
export const edit = "mdi:mdi-pencil";
export const duplicate = "fa-solid fa-fw fa-copy";
export const remove = "fa-solid fa-fw fa-trash";

/* Website Options */
export const save = "fa-solid fa-floppy-disk";
export const lock = "mdi:mdi-lock";
export const plus = "mdi:mdi-plus";

/* Success & Failure Icons */
export const cancel = "fa-solid fa-fw fa-xmark";
export const check = "fas fa-check";
export const error = "fas fa-circle-exclamation";

/* DropDown States */
export const arrowDown = "fa-solid fa-caret-down";
export const arrowRight = "fa-solid fa-caret-right";
export const arrowLeft = "fa-solid fa-caret-left";

/* Visibility States */
export const visible = "mdi:mdi-eye";
export const notVisible = "mdi:mdi-eye-off";

export const displayList = "mdi:mdi-format-list-bulleted-square";
export const displayGrid = "mdi:mdi-grid"

/* Nav Bar Options */
export const fullScreen = "mdi:mdi-fullscreen";
export const screenCapture = "mdi:mdi-camera-outline";
export const shareURL = "mdi:mdi-share";

/* Zoom Options */
export const zoomPercentage = "mdi:mdi-loupe";
export const zoomSelected = "mdi:mdi-image-filter-center-focus-strong";
export const zoomExtents = "mdi:mdi-arrow-expand-all";
export const layer = "mdi:mdi-layers";
export const multiSelect = "mdi:mdi-select-group";
export const setView = "mdi mdi-video";
export const addView = "mdi mdi-camera-plus";

/* Clipping */
export const clipping = "mdi:mdi-crop";
export const flip = "mdi:mdi-compare";

/* Account Options */
export const account = "mdi:mdi-account-circle";
export const settings = "mdi:mdi-cog";
export const logout = "mdi:mdi-exit-to-app";

/* Comment Actions */
export const comment = "mdi:mdi-message-text";
export const addComment = "mdi:mdi-comment-plus-outline";
export const refresh = "fa-solid fa-fw fa-arrows-rotate";
export const pin = "fa-solid fa-fw fa-thumbtack";
export const at = "fa-solid fa-fw fa-at";
export const circle = "fa-solid fa-fw fa-circle";
export const reply = "fa-solid fa-fw fa-reply"

/* Data Information */
export const link = "mdi:mdi-graph";
export const filter = "mdi:mdi-filter";

/* Page Properties: Attributes */
export const create = "mdi:mdi-calendar-plus";
export const update = "mdi:mdi-calendar-clock";
export const categories = "mdi:mdi-view-column";

/* Page Properties: Filter */
export const clear = "mdi:mdi-close-circle-outline";
export const cursor = "mdi:mdi-cursor-default";
export const colorBy = "fa-solid fa-fw fa-fill-drip";
export const labelBy = "mdi:mdi-square-rounded-badge";
export const highlight = "mdi:mdi-marker";

/* Filter Widget */
export const search = "fa-solid fa-magnifying-glass";
export const numerical = "fa-solid fa-hashtag";
export const range = "fa-solid fa-left-right";