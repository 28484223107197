<template>        

<v-menu >
      <template v-slot:activator="{ props }">
        <small>{{prefix}}</small>
      <v-btn variant="text" size="x-small" v-bind="props" color="black" class="ml-0 mr-2 px-0">
      <span  v-if="hasDropdown">{{ dropDownName }}{{ suffix }}<i class="fa-solid fa-caret-down"></i> </span>
      <span  v-else>{{ staticName }}{{ suffix }}</span>
      
    </v-btn>
      </template>
      <v-list v-if="hasDropdown">
        <v-list-item
          v-for="(item, index) in dropdownItems"
          :key="index"
          :value="item"
           @click="onClick(item)"
        >
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
  props: {
    dropdownItems: {
      type: [String],
      required: true
    },
    staticName: {
        type: String,
        required: true
    },
    dropDownName: {
        type: String,
        required: true
    },
    hasDropdown: {
        type: Boolean,
        required: true
    },
    prefix: {
        type: String,
        required: false,
        default: ""
    },
    suffix: {
        type: String,
        required: false,
        default: ""
    },
    onClick: {
      type: Function,
      required: true
    },
  },
  methods:{
  }
}
</script>

<style scoped></style>