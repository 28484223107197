<template>
    <div>
        <div>
            <v-row v-if="getClipType === 'plane'" class="px-2 align-center">
                <v-slider hide-details density="compact" :disabled="!getClipActive" class="py-2" v-model="sliderValue"
                    step="0.01" min='0' max="1" thumb-label="always" thumb-size="8"
                    @update:modelValue="applyClipping(sliderValue)">
                    <template #prepend>
                        <span>{{ axis }}</span>
                    </template>
                    <template v-slot:append>
                        <div class="d-flex align-center">
                            <v-tooltip location="top" text="Flip Plane" class="align-self-center">
                                <template v-slot:activator="{ props }">
                                    <v-icon :disabled="!getClipActive" v-bind="props" class=""
                                        @click="toggleFlip(flipValue)">{{
                                            icons.flip }}</v-icon>
                                </template>
                            </v-tooltip>
                            <v-checkbox class="align-self-center" :disabled="!getClipActive"
                                @change="clickOnsliderActive(sliderActive)" density="compact" hide-details="true"
                                v-model="sliderActive"></v-checkbox>
                        </div>
                    </template>
                </v-slider>
            </v-row>
            <v-row v-else class="px-2 align-center">
                <v-range-slider hide-details :disabled="!getClipActive" class="py-2" v-model="sliderValueRange"
                    step="0.01" min='0' max="1" thumb-label="always" thumb-size="10"
                    @update:modelValue="applyClipping(sliderValueRange)">
                    <template #prepend>
                        <span>{{ axis }}</span>
                    </template>
                    <template v-slot:append>
                        <div class="d-flex align-center">
                            <v-checkbox :disabled="!getClipActive" @change="clickOnsliderActive(sliderActive)"
                                density="comfortable" hide-details v-model="sliderActive"></v-checkbox>
                        </div>
                    </template>
                </v-range-slider>

            </v-row>
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import * as icons from "@/utilities/EllipseIcons.js";
export default {
    props: ["axis","sliderValues"],
    setup() {
        const notebookPropsStore = useNotebookPropsStore()
        const {
            getClipActive,
            getClipType,
        } = storeToRefs(notebookPropsStore)
        return {
            getClipActive,
            getClipType,
            icons
        }
    },
    data: () => ({
        sliderValue: 0,
        sliderValueRange: [0, 1],
        flipValue: false,
        sliderActive: true,
        currentValue: 0
    }),
    created(){
     if(this.sliderValues){
        this.sliderValue = this.sliderValues.value,
        this.sliderValueRange = this.sliderValues.valueRange,
        this.flipValue = this.sliderValues.flip,
        this.sliderActive = this.sliderValues.active
     }
  },
    methods: {
        applyClipping(newValue) {
            this.currentValue = newValue;
            let obj = {
                axis: this.axis,
                value: newValue,
                flipValue: this.flipValue,
                sliderActive: this.sliderActive
            }
            this.$emit('applyClipping', obj)
        },
        toggleFlip(value) {
            this.flipValue = !value;
            let obj = {
                axis: this.axis,
                value: this.currentValue,
                flipValue: !value,
                sliderActive: this.sliderActive
            }
            this.$emit('toggleFlip', obj);
        },
        clickOnsliderActive(value) {
            this.sliderActive = value;
            let obj = {
                axis: this.axis,
                value: this.currentValue,
                flipValue: this.flipValue,
                sliderActive: value
            }
            this.$emit('clickOnsliderActive', obj);
        }
    }
}
</script>
s
<style></style>