<template>
  <div :id="grid_item._id+'ellipse_3d_viewer'" class="widgetPadding pa-0" style="height:100%">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="(updatingWidgets || getDatasetLoading) && notebookHasModels"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning
      :show="widgetIcon && grid_item.instance_setting.data && grid_item.instance_setting.data.assets.length === 0"
      :icon= messages.widgetEmpty.icon>{{ messages.widgetEmpty.memo }}{{lexicon.model.plural}}</NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <!-- UI Overlay -->

    <!-- Controls Overlay-->
    <div class="d-flex align-self-center align-self-end zoomsStyle" v-if="grid_item.instance_setting">
      <ViewerControls :showZoom="grid_item.instance_setting.data.uiSettings.showZoom"
        :showFullScreen="grid_item.instance_setting.data.uiSettings.showFullscreen"
        :showMultiSelect="grid_item.instance_setting.data.uiSettings.showMultiSel"
        :showDownloads="grid_item.instance_setting.data.uiSettings.showDownloads" 
        :showLayers="grid_item.instance_setting.layers &&
          grid_item.instance_setting.data.uiSettings.showLayers && getAttrHeaders.length > 0"
        :layers="grid_item.instance_setting.layers && grid_item.instance_setting.layers.layers"
        :showModels="grid_item.instance_setting.data.uiSettings.showModels"
        :showCameras="grid_item.instance_setting.data.uiSettings.showCameras"
        :models="models"
        :customCameras="grid_item.instance_setting.data.cameras"
        :cameraProjection="grid_item.instance_setting.data.projection"
        :multiSelection="multiSelectActive"
        :clippingData="grid_item.instance_setting.data.clipping"
        v-on:zoomExtents="zoomExtents()" 
        v-on:zoomSelected="zoomSelected()"
        v-on:zoomByPercentage="zoomByPercentage($event)" 
        v-on:download="downloadObj()"
        v-on:fullscreen="toggleFullScreen()" 
        v-on:multiSelect="multiSelectClick()"
        v-on:layersUpdated="layersUpdated($event)" 
        v-on:layersLocked="layersLocked($event)" 
        v-on:cameraUpdated="cameraUpdated($event)" 
        v-on:cameraSaved="cameraSaved($event)" 
        v-on:camerasUpdated="camerasUpdated($event)" 
        v-on:modelUpdated="modelUpdated($event)" 
        v-on:cameraProjectionUpdated="cameraProjectionUpdated($event)" 
        :isFullScreen="isFullScreen"
        v-on:applyClipping="applyClipping($event)"
        v-on:toggleClipLocal="toggleClipLocal($event)"
        v-on:toggleClipActive="toggleClipActive($event)" 
        v-on:toggleClipShadows="toggleClipShadows($event)"
        v-on:toggleClipIntersection="toggleClipIntersection($event)"
        v-on:selectClippingType="selectClippingType($event)" 
        v-on:toggleFlip="toggleFlip($event)"
        v-on:clickOnsliderActive="clickOnsliderActive($event)"
        :id="grid_item._id+'ellipse_3d_viewer'"
        />
    </div>

    <!-- 3D Viewer -->
    <div v-if="grid_item.instance_setting" :ref="grid_item.i" style="height:100%"></div>

  </div>
</template>

<script>
import * as utils from "@/utilities"
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";

import viewerMgrInstance from "@ttcorestudio/viewer_3d/library_files/ViewerMgr.js";
import filesaver from "file-saver";
import ViewerControls from "../viewerSettings/ViewerControls.vue";
import Spinner from "../../components/ui/Spinner.vue";
import NoDataWarning from "../../components/ui/NoDataWarning.vue";
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import { FileLoader } from "@ttcorestudio/viewer_3d/library_files/FileLoader.js";
import { evaluate } from "@ttcorestudio/data-processor";
import { RGBToHex } from "@/utilities";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";

//globals
//these variables are shared accross all component instances
let viewer3DInstances = {};
let fileLoaders = {};
let customViewerSettings = {}
let viewCount = 0;

export default {
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
      getDatasetLoading,
      getPresave,
      selectedPage,
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getLabelByData,
      getFilterByData,
      getAttrData,
      getAttrHeadersCategorical,
      getAttrHeaders,
      getAttrHeaderNames,
      getFilteredInEllipseIds,
      getFilteredOutEllipseIds,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageModels,
    } = storeToRefs(assetsStore)
    const {
      getNotebook,
    } = storeToRefs(ellipseStore)
    const {
      setHighlightedElements,
      addToSelectedElements,
      removeFromSelectedElements,
      setClipActive,
      setClipType
    } = notebookPropsStore
    return {
      dataGraphicsStore,
      notebookPropsStore,
      assetsStore,
      getEditMode,
      getGlobalProperty,
      getSelectedElements,
      getHighlightedElements,
      getColorByData,
      getLabelByData,
      getFilterByData,
      getAttrData,
      getAttrHeadersCategorical,
      getAttrHeaders,
      getAttrHeaderNames,
      getSelectedPageModels,
      selectedPage,
      getNotebook,
      getPresave,
      getDatasetLoading,
      getFilteredInEllipseIds,
      getFilteredOutEllipseIds,
      setHighlightedElements,
      addToSelectedElements,
      removeFromSelectedElements,
      lexicon,
      messages,
      setClipActive,
      setClipType
    }
  },
  data() {
    return {
      updatingWidgets: false,
      datasetSwitchingLoading: false,
      isFullScreen: false,
      edges: false,
      shadow: true,
      ground: true,
      sky: false,
      groundHeight: 0,
      groundColor: "#5c5858",
      altitude: 20,
      azimuth: 190,
      labelSize: 12,
      showFullscreen: true,
      showDownloads: false,
      showLayers: true,
      showCameras: true,
      showModels: true,
      showZoom: true,
      showMultiSel: false,
      multiSelectActive: false,
      activeCamera : null,
      emptyLayers: [{ name: 'none', isVisible: true, isLocked: false, opacity: 1, elemIds: [] }],
      defaultEmptyLayers:{
        layerBy: "None",
        layers: [{ name: 'none', isVisible: true, isLocked: false, opacity: 1, elemIds: []}],
      },
      lastElementsToShow: [],
      loadedModelID: null,
      lastInnerWidth: null,
      lastInnerHeight: null,

      clipLocal: true,
      clipActive:false,
      clipShadows:false,
      clipIntersection:false,
      clippingType:'plane',
      clipDirectionsAndValues :  [{
      label: "x",
      key: "x",
      value: 0,
      active:  true,
      flip: true,
      valueRange: [0 ,1],
      min: 0,
      max: 1
      }, {
      label: "y", 
      key: "y",
      value: 0,
      active:  true,
      flip: true,
      valueRange: [0 ,1], 
      min: 0,
      max: 1
      }, {
      label: "z",
      key: "z",
      value: 0,
      active:  true,
      flip: true,
      valueRange: [0 ,1], 
      min: 0,
      max: 1
      }]
    };
  },
  components: {
    ViewerControls,
    Spinner,
    NoDataWarning,
    WidgetIcon,
    WidgetTitle,
  },
  computed: {
    instanceAssets() {
      if (this.grid_item.instance_setting && this.grid_item.instance_setting.data) {
        return this.grid_item.instance_setting.data.assets;
      }
    },
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    models() {
      let models = this.grid_item.instance_setting.data.assets;
      let extendedModels = models.map(model => {
        model.cleanName = String(model.name);
        if (model.nickname) model.cleanName = model.nickname;

        return model
      })
      return extendedModels;
    },
    notebookHasModels() {
      if (this.models && this.models.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }

  },
  created() {
    this.updatingWidgets = true
    this.verifyInstanceSettings();
  },
  async mounted() {
    this.verifyInstanceSettings();

    await this.creatingInstanceAndCanvas();
    await this.onNewGeometryReceived();

    this.updateSize();

    //Listen for changes in the notebookPropsStore
    this.notebookPropsStore.$onAction(({ name, args }) => {
      if (name === 'setHighlightedElements') {
        this.onNewElementHighlighted()
      }
      if (name === 'setGlobalHighlightColor') {
        this.globalHighlightColorEvent(args[0])
      }
      if (name === 'setGlobalSelectionColor') {
        this.globalSelectionColorEvent(args[0])
      }
      if (name === 'setGlobalSignificantDigits') {
        this.globalSignificantDigitsEvent(args[0])
      }
    })
    //Listen for changes in the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'updateColorByProperties') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateLabelByProperties') {
          this.onNewLabelByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
        if (name === 'removeAllFilters'){
          this.filtersRemoved()
        }
      })
    })
    this.assetsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdateEvent()
        }
      })
    })
    document.addEventListener('fullscreenchange', () => {
      const elem = document.getElementById(this.grid_item._id+'ellipse_3d_viewer');
      if (document.fullscreenElement !== elem && this.isFullScreen) this.isFullScreen = false
    })
  },
  beforeUnmount() {
    if (viewer3DInstances[this.grid_item.i]) {
      let clearingResponce = viewerMgrInstance.clearViewerByName(this.grid_item.i);
      viewer3DInstances[this.grid_item.i].destroy();
      delete viewer3DInstances[this.grid_item.i];
      delete fileLoaders[this.grid_item.i];
    }
  },
  watch: {
    "grid_item.instance_setting.layers.layerBy": function () {
      this.setLayersState();
      this.layersUpdated();
    },
    "grid_item.instance_setting.data.displaySettings.ground": function () {
      this.ground = this.grid_item.instance_setting.data.displaySettings.ground;
      if (viewer3DInstances[this.grid_item.i]) this.setGroundPlane();
    },
    "grid_item.instance_setting.data.displaySettings.groundcolor": function () {
      this.groundColor = this.grid_item.instance_setting.data.displaySettings.groundcolor;
      if (viewer3DInstances[this.grid_item.i]) this.setGroundPlane();
    },
    "grid_item.instance_setting.data.displaySettings.groundheight": function () {
      this.groundHeight = this.grid_item.instance_setting.data.displaySettings.groundheight;
      if (viewer3DInstances[this.grid_item.i]) this.setGroundPlane();
    },
    "grid_item.instance_setting.data.displaySettings.sky": function () {
      this.sky = this.grid_item.instance_setting.data.displaySettings.sky;
      if (viewer3DInstances[this.grid_item.i]) viewer3DInstances[this.grid_item.i].showSkybox(this.sky);
    },
    "grid_item.local_setting.foreground_color": function () {
      let backgroundColor = this.grid_item.local_setting.foreground_color;
      if (viewer3DInstances[this.grid_item.i]) viewer3DInstances[this.grid_item.i].setSceneBackground(backgroundColor);
    },
    "grid_item.instance_setting.data.displaySettings.shadow": function () {
      this.shadow = this.grid_item.instance_setting.data.displaySettings.shadow;
      if (viewer3DInstances[this.grid_item.i]) viewer3DInstances[this.grid_item.i].setLightShadows(this.shadow);
    },
    "grid_item.instance_setting.data.displaySettings.altitude": function () {
      this.altitude = this.grid_item.instance_setting.data.displaySettings.altitude;
      if (viewer3DInstances[this.grid_item.i]) viewer3DInstances[this.grid_item.i].updateElevation(this.altitude);
    },
    "grid_item.instance_setting.data.displaySettings.azimuth": function () {
      this.azimuth = this.grid_item.instance_setting.data.displaySettings.azimuth;
      if (viewer3DInstances[this.grid_item.i]) viewer3DInstances[this.grid_item.i].updateAzimuth(this.azimuth);
    },
    "grid_item.instance_setting.data.displaySettings.edges": function () {
      this.edges = this.grid_item.instance_setting.data.displaySettings.edges;
      if (viewer3DInstances[this.grid_item.i]) viewer3DInstances[this.grid_item.i].updateEdges(this.edges);
    },
    "grid_item.instance_setting.data.displaySettings.labelSize": function () {
      this.labelSize = this.grid_item.instance_setting.data.displaySettings.labelSize;
      if (viewer3DInstances[this.grid_item.i]) viewer3DInstances[this.grid_item.i].updateAllLabels(this.labelSize);
    },

    getPresave: {
      deep: true,
      handler() {
        if (viewer3DInstances[this.grid_item.i]) {
          this.updatingWidgets = true;
          // let coords = viewer3DInstances[this.grid_item.i].getCameraCoordinates();
          // this.grid_item.instance_setting.data.activeCamera = { name: "Saved Camera", value: coords, icon: "mdi:mdi-video-account", preset: false };
          let cam = viewer3DInstances[this.grid_item.i].getCurrentCameraAttributes();
          this.grid_item.instance_setting.data.activeCamera = { name: "New Camera", value: cam, icon: "mdi:mdi-video-account", preset: false };
          this.updatingWidgets = false;
        }
      }
    },
    getSelectedElements: {
      deep: true,
      handler() {
        if (viewer3DInstances[this.grid_item.i]) {
          viewer3DInstances[this.grid_item.i].clearSelection();
          if (this.getSelectedElements.length === 0) return;
          for (let i = 0; i < this.getSelectedElements.length; i++) {
            viewer3DInstances[this.grid_item.i].applySelectionMaterial(this.getSelectedElements[i]);
          }
        }
      }
    },
    instanceAssets: {
      deep: true,
      immediate: true,
      handler() {
        this.widgetAssetsChanged()
      }
    },
  },
  methods: {
    verifyInstanceSettings() {

      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!this.grid_item.instance_setting.data.displaySettings) this.grid_item.instance_setting.data.displaySettings = {};
      if (!('edges' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.edges = this.edges;
      if (!('shadow' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.shadow = this.shadow;
      if (!('ground' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.ground = this.ground;
      if (!('sky' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.sky = this.sky;
      if (!('groundheight' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.groundheight = this.groundHeight;
      if (!('groundcolor' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.groundcolor = this.groundColor;
      if (!('altitude' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.altitude = this.altitude;
      if (!('azimuth' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.azimuth = this.azimuth;
      if (!('labelSize' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.labelSize = this.labelSize;

      if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};
      if (!('showFullscreen' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showFullscreen = this.showFullscreen;
      if (!('showDownloads' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showDownloads = this.showDownloads;
      if (!('showLayers' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showLayers = this.showLayers;
      if (!('showModels' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showModels = this.showModels;
      if (!('showZoom' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showZoom = this.showZoom;
      if (!('showMultiSel' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showMultiSel = this.showMultiSel;
      if (!('showCameras' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showCameras = this.showCameras;

      if (!('filteredHeaders' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filteredHeaders = this.getAttrHeaderNames;

      if (!('layers' in this.grid_item.instance_setting)) this.grid_item.instance_setting.layers = this.defaultEmptyLayers;
      if (!('cameras' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.cameras = [];
      if (!('activeCamera' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.activeCamera = null;
      if (!('projection' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.projection = true;
  
      if (!this.grid_item.instance_setting.data.clipping) this.grid_item.instance_setting.data.clipping = {};
      if (!('clipLocal' in this.grid_item.instance_setting.data.clipping)) this.grid_item.instance_setting.data.clipping.clipLocal = this.clipLocal;
      if (!('clipActive' in this.grid_item.instance_setting.data.clipping)) this.grid_item.instance_setting.data.clipping.clipActive = this.clipActive;
      if (!('clipShadows' in this.grid_item.instance_setting.data.clipping)) this.grid_item.instance_setting.data.clipping.clipShadows = this.clipShadows;
      if (!('clipIntersection' in this.grid_item.instance_setting.data.clipping)) this.grid_item.instance_setting.data.clipping.clipIntersection = this.clipIntersection;
      if (!('clippingType' in this.grid_item.instance_setting.data.clipping)) this.grid_item.instance_setting.data.clipping.clippingType = this.clippingType;
      if (!('clipDirectionsAndValues' in this.grid_item.instance_setting.data.clipping)) this.grid_item.instance_setting.data.clipping.clipDirectionsAndValues = this.clipDirectionsAndValues;

      if (!this.layerByIsValid(this.grid_item.instance_setting.layers.layerBy)) this.setLayerByDefault();
      if (!this.layerStateIsValid()) this.setLayersState();


      if (!this.grid_item.instance_setting.data.assets) {
        this.grid_item.instance_setting.data.assets = JSON.parse(JSON.stringify(this.getSelectedPageModels))
        this.grid_item.instance_setting.data.selectedModel = this.grid_item.instance_setting.data.assets[0];
      }

      if (!this.grid_item.instance_setting.data.selectedDrawing) {
        this.grid_item.instance_setting.data.selectedDrawing = null
        if (this.grid_item.instance_setting.data.assets.length > 0) {
          this.grid_item.instance_setting.data.selectedDrawing = this.grid_item.instance_setting.data.assets[0];
        }
      }

      this.edges = this.grid_item.instance_setting.data.displaySettings.edges;
      this.shadow = this.grid_item.instance_setting.data.displaySettings.shadow;
      this.ground = this.grid_item.instance_setting.data.displaySettings.ground;
      this.sky = this.grid_item.instance_setting.data.displaySettings.sky;
      this.groundHeight = this.grid_item.instance_setting.data.displaySettings.groundheight;
      this.groundColor = this.grid_item.instance_setting.data.displaySettings.groundcolor;
      this.altitude = this.grid_item.instance_setting.data.displaySettings.altitude;
      this.azimuth = this.grid_item.instance_setting.data.displaySettings.azimuth;
      this.labelSize = this.grid_item.instance_setting.data.displaySettings.labelSize;

      this.showFullscreen = this.grid_item.instance_setting.data.uiSettings.showFullscreen;
      this.showDownloads = this.grid_item.instance_setting.data.uiSettings.showDownloads;
      this.showLayers = this.grid_item.instance_setting.data.uiSettings.showLayers;
      this.showModels = this.grid_item.instance_setting.data.uiSettings.showModels;
      this.showZoom = this.grid_item.instance_setting.data.uiSettings.showZoom;
      this.showMultiSel = this.grid_item.instance_setting.data.uiSettings.showMultiSel;
      this.showCameras = this.grid_item.instance_setting.data.uiSettings.showCameras;
    },
    multiSelectClick() {
      if (this.multiSelectActive) {
        this.multiSelectActive = false;
        viewer3DInstances[this.grid_item.i].multiSelection(false);
        this.notebookPropsStore.$patch({ selectedElements: [] })
      } else {
        this.multiSelectActive = true;
        viewer3DInstances[this.grid_item.i].multiSelection(true);
      }
    },
    newDatasetLoadStartedEvent() {
      this.datasetSwitchingLoading = true;
    },
    /**
     * Dataset Update Event - triggered when a new dataset is loaded.
     * This method is responsible for updating the layers state if needed.
     */
    datasetUpdateEvent() {
      //current layerBy key
      let layerBy = this.grid_item.instance_setting.layers.layerBy;

      //if the layerBy key is not valid, set it to default
      if (!this.layerByIsValid(layerBy)) {
        this.setLayerByDefault();
      }

      //if the layer state is not valid, update the layers state
      if (!this.layerStateIsValid()) {
        this.setLayersState();
      }

      this.datasetSwitchingLoading = false
    },
    globalHighlightColorEvent(highlight_color) {
      if (viewer3DInstances[this.grid_item.i]) {
        viewer3DInstances[this.grid_item.i].highlightColor = `0x${highlight_color
          .replace("#", "")
          .substring(0, 6)}`;
      }
    },
    globalSelectionColorEvent(selection_color) {
      if (viewer3DInstances[this.grid_item.i]) {
        viewer3DInstances[this.grid_item.i].pickColor = `0x${selection_color
          .replace("#", "")
          .substring(0, 6)}`;
      }
    },
    globalSignificantDigitsEvent(significant_digits) {
      if (viewer3DInstances[this.grid_item.i]) {
        viewer3DInstances[this.grid_item.i].digits = significant_digits;
      }
    },
    async widgetAssetsChanged() {
      //If selected model is not present in widget, change selected drawing to first model
      let selectedModelChanged = false;
      if (!this.grid_item.instance_setting.data) return

      //if selectedModel is present
      if (this.grid_item.instance_setting.data.selectedModel) {
        let selectedModelID = this.grid_item.instance_setting.data.selectedModel._id
         if ( this.loadedModelID == selectedModelID) return;   //nothing changed return
        let widgetModelIDs = this.grid_item.instance_setting.data.assets.map(model => model._id)

        //if selectedModel is in the assets list
        if (widgetModelIDs.includes(selectedModelID)) {
          this.loadedModelID = null;
          selectedModelChanged = true;
        }
        //selectedModel is not in the assets list
        else {
          //if there are assets present, change selected model to first model
          if (this.grid_item.instance_setting.data.assets.length > 0) {
            this.grid_item.instance_setting.data.selectedModel = this.grid_item.instance_setting.data.assets[0];
            selectedModelChanged = true;
          }
          //if there are no assets present, change selected model to null
          else {
            this.grid_item.instance_setting.data.selectedModel = null
            selectedModelChanged = true;
          }
        }
      } 
      //if there is no selectedModel and assets exist, change selected model to first model
      else {
        if (this.grid_item.instance_setting.data.assets.length > 0) {
          this.grid_item.instance_setting.data.selectedModel = this.grid_item.instance_setting.data.assets[0];
          selectedModelChanged = true;
        }
      }

      //if selectedModel changed, update the widget
      if (selectedModelChanged) {
        let clearingResponce = await viewerMgrInstance.clearViewerByName(this.grid_item.i);
        if (clearingResponce["status"] == "success") {
          delete viewer3DInstances[this.grid_item.i];
          delete fileLoaders[this.grid_item.i];
        }
        this.updateWidget();
        await this.creatingInstanceAndCanvas();
        await this.onNewGeometryReceived();
      }
    },
    updateSize() {
      viewer3DInstances[this.grid_item.i].onCanvasResize();
    },
    setSkyPosition() {
    },
    setGroundPlane() {
      viewer3DInstances[this.grid_item.i].setSceneGround(this.ground, this.groundColor, 1, 1000, this.groundHeight);
    },
    /**
     * Updates the LayerBy key and triggers 
     * update to the Layer State.
     * @param {string} newKeyName - new key name to set as LayerBy key.
     * @see layerByKeyIsValid() to validate the new key name before updating.
     */
    setLayerByKey(newKeyName) {
      //check if the new key is different from the current key
      let currentKey = this.grid_item.instance_setting.layers.layerBy;
      
      //if the new key is the same as the current key
      //and the current state is valid, return
      if (currentKey === newKeyName){
        //validate current state
        if (this.layerStateIsValid()) return;
      }

      //update the layerBy key
      this.grid_item.instance_setting.layers.layerBy = newKeyName;
      this.setLayersState();

      //if in dev mode - log the new layer state
      if (process.env.NODE_ENV === "development") {
      }
    },
    /**
     * Checks if a layerBy key is valid by 
     * 1. Checking if the keyName is in the list of headers
     * 2. Checking if the keyName has any values
     * @param {string} keyName - name of the headder to check 
     */
    layerByIsValid(keyName){
      //check that keyName is truthy (i.e., it exists and is not null, undefined, 0, false, or an empty string) 
      // and that its type is 'string'
      let validString = keyName && typeof keyName === 'string';

      //check if the keyName is in the list of headers
      let validHeadder = this.getAttrHeaders.filter((h) => h.name === keyName).length > 0;

      //check if the kayName has any values
      let validValues = this.getAttrData.filter((attr) => attr[keyName]).length > 0;

      //if in dev mode - log 
      if (process.env.NODE_ENV === "development") {
       // console.log('LayerBy Valid Check:', validHeadder, validValues, validString);
      }

      return validHeadder && validValues && validString;
    },
    /**
     * Layer state is Valid - checks the current layer state
     * and makes sure each item in the array is present
     * in dataset and has valid props. 
     */
    layerStateIsValid() {
      //check if layerBy is None, if so update layers to defaultEmptyLayers
      if (this.grid_item.instance_setting.layers.layerBy === 'None'){
        this.grid_item.instance_setting.layers = this.defaultEmptyLayers;
        return true;
      }

      //current layer state. 
      let layers = this.grid_item.instance_setting.layers.layers;

      //check if list of layer names matches the list of unique values in the dataset.
      let layerNames = layers.map((layer) => layer.name);
      let layerBy = this.grid_item.instance_setting.layers.layerBy;
      let elemVals = this.getAttrData.map((attr) => attr[layerBy]);
      let uniqueVals = evaluate.getUniqueVals(elemVals);
      let validNames = uniqueVals.every((name) => layerNames.includes(name));

      //check if each layer has a valid props.
      let validProps = layers.every((layer) => {
        return layer.hasOwnProperty('isVisible') &&
          layer.hasOwnProperty('isLocked') &&
          layer.hasOwnProperty('opacity') &&
          layer.hasOwnProperty('elemIds') &&
          Array.isArray(layer.elemIds);
      });

      //if in dev mode - log 
      if (process.env.NODE_ENV === "development") {
        //console.log('Layer State Valid Check:', validNames, validProps);
        //if (!validNames) console.log('Layer State Valid Check: Invalid Names', layerNames, uniqueVals);
        //if (!validProps) console.log('Layer State Valid Check: Invalid Props', layers);
      }

      return validNames && validProps;
    },
    /**
     * Checks if a layerBy value exists, if not - sets the default layerBy value.
     * Sets the layerBy key to the first valid key in the following order:
     * 1. 'Layer'
     * 2. 'Category'
     * 3. 'None'
     * This will also trigger layer state update. 
     */
    setLayerByDefault() {
      //if in dev mode - log 
      if (process.env.NODE_ENV === "development") {
        //console.log('set LayerBy Defualt Called:', currentLayerBy);
      }

      //check if a existing layerBy exists and is valid
      let currentLayerBy = this.grid_item.instance_setting.layers.layerBy;
      if (this.layerByIsValid(currentLayerBy)) return;

      //check if 'layer' is a valid key
      if (this.layerByIsValid('Layer')) {
        this.setLayerByKey('Layer');
        return;
      }

      //check if 'category' is a valid key
      if (this.layerByIsValid('Category')) {
        this.setLayerByKey('Category');
        return;
      }

      //set layerby to none
      this.grid_item.instance_setting.layers = this.defaultEmptyLayers;
    },
    /**
     * Updates layer state given current layerBy key.
     * should be called after setting the layerBy key.
     */
    setLayersState() {
      //current key
      let key = this.grid_item.instance_setting.layers.layerBy;

      //check if key is None and update empty layers.
      if (key === 'None') {
        this.grid_item.instance_setting.layers = this.defaultEmptyLayers;
        return;
      }

      //get all element Values for a given Key/headder
      let elemVals = this.getAttrData.map((attr) => attr[key]);

      //get unique values for key/headder
      let tempVal = evaluate.getUniqueVals(elemVals);

      //create new layers object for each unique value
      let newLayers = tempVal.map(
        (item) => {
          const layer = {};

          layer.name = item;
          layer.isVisible = true;
          layer.isLocked = false;
          layer.opacity = 1;

          layer.elemIds = this.getAttrData.reduce(function (acc, att) {
            if (att.hasOwnProperty(key) && att[key] === item)
              acc.push(att.ellipseId);
            return acc;
          }, []);

          return layer;
        }
      );

      //Update the layers object.
      this.grid_item.instance_setting.layers.layers = newLayers;
    },
    // Layers Settings Methods -
    layersUpdated: function (updated) {
      if (!viewer3DInstances[this.grid_item.i]) return;

      viewer3DInstances[this.grid_item.i].showAll(true);

      let hiddenElems = this.getHiddenElements();
      viewer3DInstances[this.grid_item.i].showElements(hiddenElems, false);

      let lockedElems = this.getLockedElements();
      viewer3DInstances[this.grid_item.i].lockElements(lockedElems, true );
    },
    getHiddenElements() {
      // non visible elements from filterByOpts
      let filterElems = this.getFilteredOutEllipseIds

      let layerState = this.grid_item.instance_setting.layers.layers;
      //non visible elements from layer state
      let layerElems = layerState.reduce((acc, layer) => {
        if (!layer.isVisible) acc.push(...layer.elemIds);
        return acc;
      }, []);

      //unique list of non visible elements
      let hidenElems = [...new Set([...filterElems, ...layerElems])];

      return hidenElems;
    },
    // Layers Settings Methods -
    layersLocked: function (updated) {
      // this.updateWidget();
    },
    setActiveCamera: function(){
      if(this.grid_item.instance_setting.data.activeCamera!=null && this.grid_item.instance_setting.data.activeCamera.value.type){
        viewer3DInstances[this.grid_item.i].setCamera(this.grid_item.instance_setting.data.activeCamera.value);
      }
    },
    cameraUpdated: function (camera) {
      // if(camera.preset)
      // {
      //   viewer3DInstances[this.grid_item.i].setNamedCamera(camera.value,false);
      // }
      // else
      // {
      //   viewer3DInstances[this.grid_item.i].setCamera(camera.value.position);
      // }

      if (camera.value === 'perspective') {
        viewer3DInstances[this.grid_item.i].setPerspectiveCamera();
      } else {
        if (camera.preset) {
          viewer3DInstances[this.grid_item.i].setNamedCamera(camera.value, false);
        }
        else {
          viewer3DInstances[this.grid_item.i].setCamera(camera.value);
        }
      }
    },
    async modelUpdated(model){

      this.grid_item.instance_setting.data.selectedModel = model;
      this.updatingWidgets = true;
      let clearingResponce = await viewerMgrInstance.clearViewerByName(this.grid_item.i);
      if (clearingResponce["status"] == "success") {
        delete viewer3DInstances[this.grid_item.i];
        delete fileLoaders[this.grid_item.i];
      }

      await this.creatingInstanceAndCanvas();
      await this.onNewGeometryReceived();
      // await this.updateWidget();
      this.updatingWidgets = false;
    },
    cameraSaved: function () {
      // let coords = viewer3DInstances[this.grid_item.i].getCameraCoordinates();
      // this.grid_item.instance_setting.data.cameras.push({ name: "New Camera", value: coords, icon: "mdi:mdi-video-account", preset: false })

      let cam = viewer3DInstances[this.grid_item.i].getCurrentCameraAttributes();
      this.grid_item.instance_setting.data.cameras.push({ name: "New Camera", value: cam, icon: "mdi:mdi-video-account", preset: false })
    },
    camerasUpdated: function(items){
      this.grid_item.instance_setting.data.cameras = items;
    },
    cameraProjectionUpdated: function(projection){
      this.grid_item.instance_setting.data.projection = projection;
      viewer3DInstances[this.grid_item.i].switchProjection(this.grid_item.instance_setting.data.projection);
    },
    // Zoom Options Methods -
    zoomByPercentage: function (percentage) {
      // fileLoaders[this.grid_item.i].calculateBoundingBoxOfModel(
      //   viewer3DInstances[this.grid_item.i].modelScene.children[0],
      //   percentage / 100
      // );

      viewer3DInstances[this.grid_item.i].zoomByPercentage((percentage / 100) + 0.25);
    },
    zoomExtents: function () {
      viewer3DInstances[this.grid_item.i].zoomExtents();
    },
    //TODO refactor for multi-selection
    zoomSelected: function () {
      if (this.getSelectedElements.length > 0)
        viewer3DInstances[this.grid_item.i].zoomSelection(this.getSelectedElements[0]);
    },
    async creatingInstanceAndCanvas() {
      //Get Div for new Viewer
      viewCount += 1;
      let canvasId = this.grid_item.i;
      let canvas = this.$refs[canvasId];
      this.setCustomViewerSettings();
      let response = viewerMgrInstance.addViewer(canvas, canvasId, customViewerSettings);

      if (response["status"] == "success") {
        viewer3DInstances[this.grid_item.i] = viewerMgrInstance.getViewerByName(canvasId);
        fileLoaders[canvasId] = new FileLoader(viewer3DInstances[canvasId]);
        this.updateViewerEvents(canvasId);
      }
    },
    async onNewGeometryReceived() {
      //console.log("onNewGeometryReceived")

      if (this.grid_item.instance_setting.data.selectedModel == null) return

      if (this.loadedModelID === this.grid_item.instance_setting.data.selectedModel._id) return;
      // prevent to load happeing again
      this.loadedModelID = this.grid_item.instance_setting.data.selectedModel._id;
      //console.log("loading process contunie first time")

      this.updatingWidgets = true;

      //get signed url for 3D model to pass to viewer load method
      let signed_url = await this.$auth.$api.post(
        `/api/notebook/${this.getNotebook._id}/file/get-s3-signed-urls/?urlType=${this.$route.name}`,
        {
          key: this.grid_item.instance_setting.data.selectedModel
            .s3_pointers[0],
        }
      );
      
      //* Load from file url
      fileLoaders[this.grid_item.i].rhino3dmFileLoadfromUrl(signed_url.data.url, this.grid_item.instance_setting.data.selectedModel.name);
      

      //* TEST TEST TEST load from array
      // console.time('rhino3dmFileLoadfromBufferArray');
      // const response = await fetch(signed_url.data.url);
      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }
      // const buffer = await response.arrayBuffer();
      // fileLoaders[this.grid_item.i].rh3dmFileLoadByteArray(buffer);
      //* TEST TEST TEST

      fileLoaders[this.grid_item.i].addEventListener("load-finished", (e) => {
        console.timeEnd('rhino3dmFileLoadfromUrl');
        //console.timeEnd('rhino3dmFileLoadfromBufferArray');
        if (this.getLabelByData.graphicData) this.onNewLabelByUpdated();
        if (this.getColorByData.graphicData) this.onNewColorByUpdated();
        if (this.getFilterByData.graphicData) this.onNewFilterByUpdated();
        
        //update layers
        if (this.grid_item.instance_setting.layers) {
          this.layersUpdated();
        }

        //Update View Settings. 
        this.updateDisplaySettings();
        this.setActiveCamera();
        this.setClippingValues();
        this.updatingWidgets = false;
      });
    },
    async onNewElementHighlighted() {
      if (viewer3DInstances[this.grid_item.i]) {
        viewer3DInstances[this.grid_item.i].clearHighlights();
        if (!this.getHighlightedElements) return;
        viewer3DInstances[this.grid_item.i].applyHighlightMaterials(this.getHighlightedElements);
      }
    },
    async onNewColorByUpdated() {
      if (!viewer3DInstances[this.grid_item.i]) return;
      viewer3DInstances[this.grid_item.i].resetAllColors();
      let opts = this.getColorByData.graphicData;

      if (!opts) return;
      let rgbColors = [];
      for (let idx = 0; idx < opts.ids.length; idx++) {
        let rgbStr = opts.colors[idx];
        rgbStr = rgbStr.slice(4, rgbStr.length - 1);
        let rgbArr = rgbStr.split(",").map((s) => parseInt(s.trim()));
        let c = RGBToHex(...rgbArr, "0x");
        rgbColors.push(c);
      }
      viewer3DInstances[this.grid_item.i].colorElements(opts.ids, rgbColors, false);
      viewer3DInstances[this.grid_item.i].ghostElements(opts.ids_inValidValue)
    },
    async onNewLabelByUpdated() {
      if (!viewer3DInstances[this.grid_item.i]) return;
      if (this.getLabelByData.attribute == "None") {
        viewer3DInstances[this.grid_item.i].clearAllLabels();
        return;
      }
      let opts = this.getLabelByData.graphicData;
      if (!opts) return;
      let rgbColors = [];
      for (let idx = 0; idx < opts.ids.length; idx++) {
        let rgbStr = opts.colors[idx];
        rgbStr = rgbStr.replace(/\s/g, "");

        rgbColors.push(rgbStr);
      }

      viewer3DInstances[this.grid_item.i].labelElements(opts.ids, opts.values, rgbColors);
      // viewer3DInstances[this.grid_item.i].ghostElements(opts.ids_inValidValue)
    },
    async onNewFilterByUpdated() {
      if (!viewer3DInstances[this.grid_item.i]) return;
      let hidenElems = this.getHiddenElements();
      viewer3DInstances[this.grid_item.i].showAll(true);
      viewer3DInstances[this.grid_item.i].showElements(hidenElems, false);
    },
    async filtersRemoved(){
       if (!viewer3DInstances[this.grid_item.i]) return;
      let elementsToHide = this.getHiddenElements();
      viewer3DInstances[this.grid_item.i].showAll(true);
      viewer3DInstances[this.grid_item.i].showElements(elementsToHide, false);
    },
    getElementsToShow() {
      let dataInRange = this.getFilterByData.graphicData;
      let ellipseIdsInRange = dataInRange ? dataInRange.map(data => data.ellipseId) : null

      let layerState = this.grid_item.instance_setting.layers.layers;
      
      // Accumulate ids of elements in visible layers
      let layerElems = layerState.reduce((acc, layer) => {
        if (layer.isVisible) acc.push(...layer.elemIds);
        return acc;
      }, []);

      // Find common IDs between ellipseIdsInRange and layerElems
      if (ellipseIdsInRange) return [...new Set([...ellipseIdsInRange], ...layerElems)]
      else return layerElems
    },
    getLockedElements() {
      let layerState = this.grid_item.instance_setting.layers.layers;
      let lockedElems = layerState.reduce((acc, layer) => {
        if (layer.isLocked) acc.push(...layer.elemIds);
        return acc;
      }, []);
      return lockedElems;
    },
    async updateWidget() {
      let postData = {
        widgets: [this.grid_item],
      };

      let update_widgets = await this.$auth.$api.post(
        `/api/widget/update_widgets/?urlType=${this.$route.name}`,
        postData
      );
    },
    //these are not properly handled by the 3D viewer library. 
    setCustomViewerSettings() {

      customViewerSettings = {
        backgroundColor: 0xfafafa,
        pickColor: 0x00ff00,
        highlightColor: 0xff0000,
        ghostColor: 0xf3f3f3,
        showGround: this.ground,
        showShadows: this.shadow,
        showSky: this.sky,
        groundColor: this.groundColor,
        groundOpacity: 1,
        groundHeight: this.groundHeight,
        groundScale: 1000,
        elevation: this.altitude,
        azimuth: this.azimuth,
        showEdges: this.edges,
        labelSize: this.labelSize,
        digits: this.getGlobalProperty.global_setting.significant_digits,
        tooltipColor: this.$vuetify.theme.current.colors.darkSlate,
      };

      let selectionColor = `0x${this.getGlobalProperty.global_setting.selection_color
        .replace("#", "")
        .substring(0, 6)}`;
      let highlightColor = `0x${this.getGlobalProperty.global_setting.highlight_color
        .replace("#", "")
        .substring(0, 6)}`;
      let backgroundColor = `0x${this.grid_item.local_setting.foreground_color
        .replace("#", "")
        .substring(0, 6)}`;

      customViewerSettings.pickColor = parseInt(selectionColor);
      customViewerSettings.highlightColor = parseInt(highlightColor);
      customViewerSettings.backgroundColor = parseInt(backgroundColor);

    },
    updateActiveCamera()
    {
      this.activeCamera = this.grid_item.instance_setting.data.activeCamera;
      if (viewer3DInstances[this.grid_item.i]) {
          // if(activeCamera.preset)
          // {
          //   viewer3DInstances[this.grid_item.i].setNamedCamera(activeCamera.value,false);
          // }
          // else
          // {
          //   viewer3DInstances[this.grid_item.i].setCamera(activeCamera.value.position);
          // }
          if (activeCamera.value === 'perspective') {
            viewer3DInstances[this.grid_item.i].setPerspectiveCamera();
          } else {
            if (activeCamera.preset) {
              viewer3DInstances[this.grid_item.i].setNamedCamera(activeCamera.value, false);
            }
            else {
              viewer3DInstances[this.grid_item.i].setCamera(activeCamera.value);
            }
          }
            } 
    },
    updateDisplaySettings() {
      if (viewer3DInstances[this.grid_item.i]) {
        
        //Ground Plane
        if (this.ground != undefined && this.groundColor && this.groundHeight) {
          this.setGroundPlane();
        }

        //Skybox
        if (this.sky !== undefined && this.sky !== null) {
          viewer3DInstances[this.grid_item.i].showSkybox(this.sky);
        }

        //Scene Background Color
        if (this.backgroundColor) {
          viewer3DInstances[this.grid_item.i].setSceneBackground(this.backgroundColor);
        }

        //Shadows
        if (this.shadow !== undefined && this.shadow !== null) {
          viewer3DInstances[this.grid_item.i].setLightShadows(this.shadow);
        }

        //Sun Elevation
        if (this.altitude) {
          viewer3DInstances[this.grid_item.i].updateElevation(this.altitude);
        }

        //Sun Azimuth
        if (this.azimuth) {
          viewer3DInstances[this.grid_item.i].updateAzimuth(this.azimuth);
        }

        //Edges
        if (this.edges != undefined && this.edges != null) {
          viewer3DInstances[this.grid_item.i].updateEdges(this.edges);
        }

        //Label Size
        if (this.labelSize) {
          viewer3DInstances[this.grid_item.i].updateAllLabels(this.labelSize);
        }

      }
    },
    updateViewerEvents(viewerId) {
      //Multi Select Refactor Here....
      viewer3DInstances[viewerId].multiSelection(false);
      viewer3DInstances[viewerId].highlightCheck(true);
      viewer3DInstances[viewerId].registerEvent("element-select", this.elementSelectEvent);
      viewer3DInstances[viewerId].registerEvent("element-highlight", (e) => {
        this.setHighlightedElements([e]);
        this.setTooltipContent();
      });
    },
    getHighlightedElementsAttributes() {
      let idSet = new Set(this.getHighlightedElements);
      // Filter the based on the presence of the ID in the Set
      return this.getAttrData.filter((obj) => idSet.has(obj.ellipseId));
    },
    setTooltipContent() {
      if (viewer3DInstances[this.grid_item.i]) {
        let attributes =this.getHighlightedElementsAttributes();
        if (attributes) {

          let results = utils.getColorByLabel(attributes, this.getColorByData.graphicData, this.grid_item.instance_setting.data.filteredHeaders.map((x) => x), this.getGlobalProperty.global_setting.color_by_attribute);
          let values = results.label;

          values = values + utils.tabulatedAttributeToList(utils.tabulateAttributes(attributes, this.getAttrHeaders, 'variable'), results.headers);
          viewer3DInstances[this.grid_item.i].setTooltipContent(values);
        }
      }
    },
    elementSelectEvent(ellipseId) {
      if (this.multiSelectActive) {
        if (this.getSelectedElements.includes(ellipseId)) {
          this.removeFromSelectedElements(ellipseId)
        } else {
          this.addToSelectedElements(ellipseId)
        }
      } else {
        if (ellipseId === "") {
          this.notebookPropsStore.$patch({ selectedElements: [] })
        }
        else {
          this.notebookPropsStore.$patch({ selectedElements: [ellipseId] })
        }
      }
    },
    toggleFullScreen() {
      const elem = document.getElementById(this.grid_item._id+'ellipse_3d_viewer');
      if (document.fullscreenElement === elem){
        this.closeFullscreen();
      }
      else {
        this.openFullscreen();
      }
    },
    openFullscreen() {
      const elem = document.getElementById(this.grid_item._id+'ellipse_3d_viewer');
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      this.isFullScreen = true;
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      this.isFullScreen = false
    },
    downloadObj() {
      var obj = viewer3DInstances[this.grid_item.i].toObjString();
      const blob = new Blob([obj], {
        type: 'text/plain' // or whatever your Content-Type is
      });
      filesaver.saveAs(blob, this.getNotebook.name + "-" + this.selectedPage.name + "-" + this.grid_item.instance_setting.data.selectedModel.name.replace(/\.[^/.]+$/, "")+ "-" + utils.timeStamp()+".obj");
    },
    selectClippingType(data) {
      this.setClipType(data);
      viewer3DInstances[this.grid_item.i].clippingType = data;
      //viewer3DInstances[this.grid_item.i].updateClipping(this.grid_item.instance_setting.data.selectedModel.name);
      this.grid_item.instance_setting.data.clipping.clippingType = data;
      this.setClippingValues();
    }, 
    toggleClipLocal(data) {
      viewer3DInstances[this.grid_item.i].clipLocal = data;
      viewer3DInstances[this.grid_item.i].updateClipping(this.grid_item.instance_setting.data.selectedModel.name);
      this.grid_item.instance_setting.data.clipping.clipLocal = data;
    },
    toggleClipActive(data) {
      this.setClipActive(data);
      viewer3DInstances[this.grid_item.i].clipActive = data;
      //viewer3DInstances[this.grid_item.i].updateClipping(this.grid_item.instance_setting.data.selectedModel.name);
      this.grid_item.instance_setting.data.clipping.clipActive = data;
      this.setClippingValues();

    }, 
    toggleClipShadows(data) {
      viewer3DInstances[this.grid_item.i].clipShadows = data;
      viewer3DInstances[this.grid_item.i].updateClipping(this.grid_item.instance_setting.data.selectedModel.name);
      this.grid_item.instance_setting.data.clipping.clipShadows = data;
    }, 
    toggleClipIntersection(data) {
      viewer3DInstances[this.grid_item.i].clipIntersection = data;
      viewer3DInstances[this.grid_item.i].updateClipping(this.grid_item.instance_setting.data.selectedModel.name);
      this.grid_item.instance_setting.data.clipping.clipIntersection = data;
    }, 
    toggleFlip(data) {
      this.applyClipping(data);
    }, 
    clickOnsliderActive(data) {
      this.applyClipping(data);
    }, 
    applyClipping(data) {
      // console.log('applyClipping', data);
      const direction = this.grid_item.instance_setting.data.clipping.clipDirectionsAndValues.find(item => item.key === data.axis);
      if (viewer3DInstances[this.grid_item.i].clippingType === 'plane') {
        viewer3DInstances[this.grid_item.i].setClippingAxis(data.axis, data.value, data.flipValue, data.sliderActive);
        if (direction) Object.assign(direction, { value:data.value, flip:data.flipValue, active:data.sliderActive});
      } else {
        viewer3DInstances[this.grid_item.i].setClippingDirection(data.axis, [...data.value], data.sliderActive);
        if (direction) Object.assign(direction, { valueRange:[...data.value], active:data.sliderActive});
      }
     
      viewer3DInstances[this.grid_item.i].updateClipping(this.grid_item.instance_setting.data.selectedModel.name);
    },
    setClippingValues(){
      if(this.grid_item.instance_setting.data.clipping){
          viewer3DInstances[this.grid_item.i].clipLocal = this.grid_item.instance_setting.data.clipping.clipLocal;
          viewer3DInstances[this.grid_item.i].clipActive = this.grid_item.instance_setting.data.clipping.clipActive;
          viewer3DInstances[this.grid_item.i].clipShadows = this.grid_item.instance_setting.data.clipping.clipShadows;
          viewer3DInstances[this.grid_item.i].clipIntersection = this.grid_item.instance_setting.data.clipping.clipIntersection;
          viewer3DInstances[this.grid_item.i].clippingType = this.grid_item.instance_setting.data.clipping.clippingType;
          
          if (viewer3DInstances[this.grid_item.i].clippingType === 'plane') {
            this.grid_item.instance_setting.data.clipping.clipDirectionsAndValues.forEach(e => {
              viewer3DInstances[this.grid_item.i].setClippingAxis(e.key, e.value, e.flip, e.active);
            })
          } else {
            this.grid_item.instance_setting.data.clipping.clipDirectionsAndValues.forEach(e => {
              viewer3DInstances[this.grid_item.i].setClippingDirection(e.key, [...e.valueRange], e.active);
            })

          }

          viewer3DInstances[this.grid_item.i].updateClipping(this.grid_item.instance_setting.data.selectedModel.name);
          this.setClipActive(this.grid_item.instance_setting.data.clipping.clipActive);
          this.setClipType(this.grid_item.instance_setting.data.clipping.clippingType);


      }
    }
  },
};
</script>

<style scoped>
.v-input:deep(.v-select__selection) {
  display: flex;
  align-items: center;
}

.v-input:deep(.v-select__selection-text) {
  font-size: 14px !important;
  width: 170px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px
}
</style>
